<template>
	<main class="vcc-main">

		<h1 class="heading">Вебинары</h1>
		<section class="webinars--upcoming-webinars">
			<h2 class="heading heading__hr hr__bold">Ближайшие вебинары</h2>
				<div class="webinars--slider" v-if="isMobile">
					<card-slider>
						<webinar-card
							v-for="webinar, key in getActiveWebinars.slice(0, 4)"
							:key="'webinar-'+key"
							:webinar_data="webinar"
							:is_past="false"
							:no_btns="false"
							class="swiper-slide"
						/>
					</card-slider>
				</div>
			<ul class="wibinars--webinars-list" v-else>
				<li v-for="webinar, key in getActiveWebinars.slice(0, 4)" :key="'webinar-'+key">
					<webinar-card :webinar_data="webinar" :is_past="false" :no_btns="false"/>
				</li>
			</ul>
			<div class="faq--btn-more-wrapper" v-if="false">
				<router-link :to="{name: 'WebinarsArchive'}" class="link__btn btn__blue">Показать еще</router-link>
			</div>
		</section>

		<illustrated-form type="subs1"/>

		<section class="webinars--past-webinars">
			<h2 class="heading heading__hr hr__bold">Прошедшие вебинары</h2>
			<div class="webinars--slider" v-if="isMobile">
				<card-slider>
					<webinar-card
						v-for="past_webinar, key in getWebinars.slice(0, 4)"
						:key="'past-webinar-'+key"
						:is_past="true"
						:no_btns="false"
						:webinar_data="past_webinar"
						class="swiper-slide"
					/>
				</card-slider>
				<div class="webinars--archive-btn" >
				<router-link :to="{name: 'WebinarsArchive'}" class="link__btn btn__white btn__icon_grid btn__icon_grid-slim">
					<div class="btn--container__square">
						<div class="btn--image">
							<icon-video-page/>
						</div>
						<span>В архив вебинаров</span>
					</div>
				</router-link>
				</div>
			</div>

			<ul class="past-webinars--list" v-else>
				<li v-for="past_webinar, key in getWebinars.slice(0, 3)" :key="'past-webinar-'+key">
					<webinar-card :is_past="true" v-if="past_webinar" :no_btns="true" :webinar_data="past_webinar"/>
				</li>
				<li>
					<router-link :to="{name: 'WebinarsArchive'}" class="link__btn btn__white btn__icon_grid btn__icon_grid-slim">
						<div class="btn--container__square">
							<div class="btn--image">
								<icon-video-page/>
							</div>
							<span>В архив вебинаров</span>
						</div>
					</router-link>
				</li>
			</ul>
		</section>

		<calendar-section :is-expanded="isDesktop" />

		<vcc-faq :subheading="true" />
	</main>
</template>

<script>
import IconVideoPage from '@/assets/svg/IconVideoPage.vue'
import CalendarSection from '@/components/CalendarSection.vue'
import Webinar from '@/components/cards/Webinar.vue'
import Illustrated from '@/components/forms/Illustrated.vue'
import Faq from '@/components/FaqSection.vue'
import { mapActions, mapGetters } from 'vuex'
import CardSlider from '../components/sliders/CardSlider.vue'
import deviceWidth from '@/mixins/deviceWidth.js'

export default {
	components: {
		'webinar-card': Webinar,
		'illustrated-form': Illustrated,
		'calendar-section': CalendarSection,
		'vcc-faq': Faq,
		CardSlider,
		IconVideoPage,
	},
	mixins: [ deviceWidth ],
	computed: {
		...mapGetters(['getAllWebinars', 'getActiveWebinars', 'getWebinars']),
	},
	methods: mapActions(['fetchAllWebinars', 'fetchAllWebinarsFromWebinarRu']),
	created() {
		if(this.getWebinars.length == 0) this.fetchAllWebinars()
	}
}
</script>

<style>

</style>
